@use './utilities/variables' as scss;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFDFA;

  &.menu-open {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
}

p {
  margin: 0;
}

a {
  cursor: pointer;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

ul,
ol {
  list-style: none;
}

input,
textarea,
button,
a {
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

* {
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;

  &.footer-wrapper {
    max-width: 1300px;
    padding: 0;
  }

  &.with-padding {
    padding: 100px 10px;
  }
}

.back-button {
  display: inline-flex;
  font-size: 16px;
  color: scss.$blue;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
  transition: .3s;

  &:hover {
    color: darken(scss.$blue, 10);
  }
}

.orange {
  color: scss.$orange;
}

.show-on-small-screen {
  display: none;

  @include scss.on-small-screen {
    display: block;
  }
}

.text {
  font-size: 16px;
  line-height: 1.4;
}
