@use '../../utilities/variables' as scss;

.checkbox {
  height: 23px;
  width: 23px;
  min-width: 23px;
  border: 3px solid #C8C8C8;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color .3s;

  &.is-checked {
    background-color: scss.$blue;
    background-repeat: no-repeat;
    background-image: url("../../images/icons/ckeckbox.png");
    background-position: center;
    border: 3px solid scss.$blue;
  }

  &.is-small {
    height: 20px;
    width: 20px;
    min-width: 20px;
  }
}
