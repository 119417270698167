@use '../../utilities/variables' as scss;

.social {
  width: 200px;
  display: flex;
  justify-content: space-between;

  &__link {
    cursor: pointer;
  }

  &__modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 20px;
    font-weight: bold;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  &__modal-qr {
    width: 250px;
    height: 250px;
  }

  &__modal-id {
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
    color: scss.$black;
  }
}
