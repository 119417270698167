@use '../../utilities/variables' as scss;

.footer {
  background-color: scss.$black;
  padding: 60px 40px 0 40px;

  @include scss.on-small-screen {
    padding-bottom: 20px;
  }

  @include scss.on-tablet {
    padding: 40px 20px 20px 20px;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    @include scss.on-tablet {
      flex-direction: column;
      align-items: center;
    }
  }

  &__column {
    padding: 0 10px;

    @include scss.on-tablet {
      padding: 0;
    }

    &.first-column {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      @include scss.on-small-screen {
        display: none;
      }
    }

    &.last-column {
      max-width: 350px;
    }

    &.middle-column {
      padding-left: 50px;

      @include scss.on-small-screen {
        padding-left: 0;
      }
    }
  }

  &__links {
    color: scss.$white;

    @include scss.on-small-screen {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
    }

    @include scss.on-tablet {
      align-items: center;
    }
  }

  &__link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-block;

    @include scss.on-small-screen {
      margin-bottom: 15px;;
    }

    @include scss.on-tablet {
      margin-right: 0;
      text-align: center;
    }

    &.active {
      color: scss.$orange;
      font-weight: 700;
    }
  }

  &__data {
    max-width: 420px;
    color: #9999A7;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 50px;

    @include scss.on-small-screen {
      margin-top: 20px;
    }

    @include scss.on-tablet {
      display: none;
    }
  }

  &__cards {
    display: flex;
    justify-content: flex-start;
    margin: 30px 0;

    @include scss.on-small-screen {
      display: none;
    }
  }

  &__card {
    background-position: center;
    width: 68px;
    height: 46px;

    &.visa {
      background-image: url("../../images/cards/visa.png");
    }

    &.ver-visa {
      background-image: url("../../images/cards/vervisa.png");
    }

    &.master {
      background-image: url("../../images/cards/master.png");
    }

    &.master-card {
      background-image: url("../../images/cards/mastercard.png");
    }

    &.union {
      background-image: url("../../images/cards/union.png");
    }

    &.belcard {
      background-image: url("../../images/cards/belcard.png");
    }

    &.mir {
      background-image: url("../../images/cards/mir.png");
    }
  }

  &__social {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    height: 110px;
    position: relative;

    @include scss.on-tablet {
      align-items: center;
      margin-top: 20px;
      height: 130px;
    }
  }

  &__social-text {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: right;
    margin-top: 28px;
    line-height: 1.3;

    @include scss.on-tablet {
      display: none;
    }
  }

  &__social-arrow {
    position: absolute;
    bottom: -38px;
    right: -25px;
    width: 21px;
    height: 44px;
    background-position: center;
    background-image: url("../../images/icons/curved-arrow.png");

    @include scss.on-tablet {
      display: none;
    }
  }

  &__phone-link {
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
  }

  &__phone-icon {
    margin-right: 10px;
  }

  &__phone-number {
    font-size: 18px;
    font-weight: 600;
    color: scss.$blue;
  }

  &__job {
    padding: 40px 12px 20px 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    width: 143px;
    height: 154px;
    background-image: url("../../images/baner.png");
    color: #fff;
  }

  &__job-text {
    text-align: center;
  }

  &__job-logo {
    font-size: 18px;
    font-weight: 700;
    margin: 15px 0;
  }

  &__job-wage {
    color: scss.$orange;
    font-size: 24px;
    font-weight: 700;
    margin-right: 30px;
  }

  &__job-currency {
    font-size: 10px;
    font-weight: 500;
    width: 33px;
    display: inline-block;
    margin-left: 5px;
    position: absolute;
    bottom: 18px;
    right: 8px;
  }
}
